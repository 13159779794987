<template>
  <section class="space-y-10">
    <QuickInfo />

    <asom-alert v-if="error" variant="error" :error-message="error" />

    <article v-if="canMoveAFCCashBags">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-3">
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('cashbag/movement')"
          icon="swap"
          text="Move Cash Bag"
        >
        </asom-button>
      </section>
    </article>

    <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <table class="min-w-full divide-y divide-gray-200">
        <caption class="hidden"></caption>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Certis Bags
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              TE Bags
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200 text-sm">
          <template v-for="(bagPair, index) in bagPairList" :key="index">
            <tr>
              <td class="px-6 py-4 whitespace-nowrap w-1/2">
                <span>{{ bagPair.certisBagNo }}</span>
                <asom-badge v-if="bagPair.isCertisBagMoveOut" class="ml-2">
                  In Transit
                </asom-badge>
                <asom-badge
                  v-if="bagPair.isCertisBagInSafe"
                  class="ml-2"
                  variant="secondary"
                >
                  In safe
                </asom-badge>
              </td>
              <td class="px-6 py-4 whitespace-nowrap w-1/2">
                {{ bagPair.teBagNo }}
                <asom-badge
                  v-if="bagPair.isTeBagInSafe"
                  class="ml-2"
                  variant="secondary"
                >
                  In safe
                </asom-badge>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-lg font-medium text-gray-900 uppercase tracking-wider"
            >
              ${{ sumCertisBags }}
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-lg font-medium text-gray-900 uppercase tracking-wider"
            >
              ${{ sumTeBags }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>

   
    <asom-card title="Search Transactions">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-6 gap-4">
       
        <asom-form-field label="Date" required>
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
          />
        </asom-form-field>
      </div>
      <template #footer>        
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getTransactionPageData" />
      </template>
    </asom-card>
        
    <article>
      <h3 class="subheader">
        Transactions
      </h3>
      <br />
      <asom-client-table
        :data="transaction.cashBagTransactions"
        :columns="[
          'transactionNo',
          'createdDate',
          'time',
          'rosterDWS',
          'isTEBag',
          'bagNo',
          'transactionType',
          'location',
          'description',
          'documentReference',
          'amount',
          'createdBy',
        ]"
        :sortableColumns="[
          'transactionNo',
          'rosterDWS',
          'bagNo',
          'transactionType',
          'location',
          'description',
          'documentReference',
          'amount',
          'createdBy',
          'createdDate',
          'isTEBag',
        ]"
        :searchableDateColumns="['createdDate']"
        :searchableTimeColumns="['createdDate']"
        :searchableCurrencyColumns="['amount']"
      >
        <template v-slot:header_bagNo>S/N</template>
        <template v-slot:header_description>Description / Reason</template>
        <template v-slot:header_transactionType>Action</template>
        <template v-slot:header_createdBy>Staff</template>
        <template v-slot:header_rosterDWS>Shift</template>
        <template v-slot:header_createdDate>Date</template>
        <template v-slot:header_isTEBag>Input Type</template>
        <template v-slot:createdDate="slotScoped">
        {{
          displayUtcDate(slotScoped.data)
        }}</template>
        <template v-slot:time="slotScoped">{{
          displayUtcTime(slotScoped.rowData.createdDate)
        }}</template>
        <template v-slot:amount="slotScoped">{{formatCurrencyCustom(slotScoped.data)}}</template>
        <template v-slot:transactionType="slotScoped">
          {{getActionText(slotScoped.rowData)}}
        </template>
        <template v-slot:isTEBag="slotScoped">{{
          slotScoped.data ? "TE Bag" : "Certis Bag"
        }}</template>
      </asom-client-table>
    </article>

    <article>
      <h3 class="subheader">
        Activities
      </h3>
      <br />
      <activity-log-list :data="transaction.activityLogs" />
    </article>
  </section>
</template>

<script>
import moment from "moment";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { mapGetters } from "vuex";
import { getAFCCashBagSummary,getListOfCashBagTransaction } from "@/services/cashManagement.service";
import {toMoment, displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import QuickInfo from "../../_QuickInfo.vue";
import ActivityLogList from "../../_ActivityLogList.vue";
import { formatCurrency } from "@/helpers/numbers";
import Decimal from 'decimal.js';

export default {
  components: {
    QuickInfo,
    ActivityLogList,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      summary: {
        certisBags: [],
        teBags: [],
      },
      filters: {       
        dateRange: {
          start: moment().subtract(1, "month").toDate(),
          end: moment().toDate(),
        },
      },
      transaction: {
        cashBagTransactions: [],
        activityLogs: [],
      }
    };
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      canMoveAFCCashBags: 'auth/canMoveAFCCashBags',
    }),
    bagPairList() {
      const result = [];
      const len =
        this.summary.certisBags.length > this.summary.teBags.length
          ? this.summary.certisBags.length
          : this.summary.teBags.length;
      for (let i = 0; i < len; i++) {
        result.push({
          certisBagNo: get(this.summary.certisBags, `${i}.bagNo`),
          isCertisBagMoveOut:
            get(this.summary.certisBags, `${i}.lastTransaction`) === "Move Out",
          teBagNo: get(this.summary.teBags, `${i}.bagNo`),
          isCertisBagInSafe: get(this.summary.certisBags, `${i}.inSafe`),
          isTeBagInSafe: get(this.summary.teBags, `${i}.inSafe`),
        });
      }
      return result;
    },
    sumCertisBags() {
      return this.summary.certisBags.reduce((sum, bag) => sum.plus(new Decimal(bag.amount)), new Decimal(0)).toFixed(2);
    },
    sumTeBags() {
      return this.summary.teBags.reduce((sum, bag) => sum.plus(new Decimal(bag.amount)), new Decimal(0)).toFixed(2);
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(this.filters.dateRange.start, true);
        params["searchDateTo"] = this.parseDateTimeUTC(this.filters.dateRange.end, false);
      }      
      return params;
    },
  },
  mounted() {
    this.getSummaryData();
    this.getTransactionPageData();
  },
  methods: {
    displayUtcDate,
    displayUtcTime,
    resetFilters() {
      this.filters.dateRange = null;
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf('day') : d.endOf('day');
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss")
      }
      return ""
    },
    formatCurrencyCustom(value) {
      if (value) return formatCurrency(value);
      return "N.A"
    },
    async getSummaryData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch('cashManagement/loadStationCashAccounts');
      const result = await getAFCCashBagSummary(this.afcId);
      if (result.success) {
        this.error = null;
        this.summary.certisBags = get(result, "payload.certisBags", []);
        this.summary.teBags = get(result, "payload.teBags", []);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    getActionText({
      transactionType,
      witness
    }) {
      if (!isEmpty(witness)) {
        return `${transactionType} (${witness})`;
      }
      return transactionType;
    },
    async getTransactionPageData() {
      const resp = await getListOfCashBagTransaction({  
        aFCId: this.afcId,      
        ...this.queryParams,
      });
      if (resp.success) {
        this.error = null;
         this.transaction.cashBagTransactions = get(
            resp,
            "payload.transactions",
            []
          );
          this.transaction.activityLogs = get(resp, "payload.activityLogs", []);
        
      } else {
        this.error = resp.payload;
        this.$scrollTop();        
      }
    },
  },
};
</script>
